/*AcctX Custom CSS*/

/* do not print the sidebar to window.print()*/
@media print {
  .sidebar-no-print {
    display: none !important;
    margin-left: 0;
    width: 100%;
  }
}
/*datatables stuff */

div.dataTables_length select {
  color: whitesmoke !important;
}


.page-item .page-link {
box-shadow: none;
}

.page-item.active .page-link {
color: #fff;
background-color: #6c757d;
border-color: #6c757d;
box-shadow: none;
}

.dataTables_filter {
  float: left !important;
}


.dataTables_filter input {
  color: lightgray;
  margin: 1em;
  border: none !important;
  border-radius: 5px !important;
  background: rgba(63,73,84,0.77) !important;
  box-shadow: none !important;
}
input:focus {
  background-color:  rgba(63,73,84,0.77);
  border: none;
  outline: none;
}

/* end datatables stuff */

body {
  background-position: top;
  background-repeat: repeat-y;
  background-size: 100%;
  padding-bottom: 300px;
  transition: background 0.5s ease-in-out;
  -moz-transition: background 0.5s ease-in-out;
  -webkit-transition: background 0.5s ease-in-out;
}

.body-beach1 h2, .bats h2, .red-trees h2,
.blue-water h2, .red-water h2, .christmas h2,
.city h2, .flash h2, .red-bicycle h2, .sunset-hills h2
  /*,. h1, . h2, . h3,*/ {
  color: #fff !important;
}

.modal-header h2 {
  color: #000 !important;
}

.bg-PanelHeader {
  background-color: #dcdcdc !important;
}
.white {
  color: #fff !important;
}

.body-wasabi {
  background: #afd76b;
}

.body-seafoam {
  background: #91dcdf;
}

.body-rose {
  background: #f5c0d0;
}

.body-mustard {
  background: #f6dc76;
}

.body-light {
  background-color: #fcfcfc;
}

.body-lavender {
  background: #cab6eb;
}

.body-gray {
  background: #f5f5f5;
}

.body-blue {
  background-color: #b2d5ee;
}

.max-height-50vh {
  max-height: 50vh;
  overflow-y: auto;
}

[href],
[ng-href],
[ui-sref],
[ng-click],
[data-ng-click],
[x-ng-click] {
  cursor: pointer;
}

.date-picker {
  z-index: 100;
  position: relative;
}

.bottom-spacer {
  height: 10vh;
}

.text-normal {
  font-weight: normal !important;
}

@media (max-width: 1190px) {
  .text-center-sm {
    text-align: center;
  }
}

.box-shadow {
  box-shadow: 0 0 20px #ccc;
}

.fixed,
.fixed-top {
  position: fixed;
  width: 100%;
  left: 0;
  z-index: 100;
}

.fixed {
  top: 50px;
}

.fixed-top {
  top: 0px;
}

.fixed-spacer-1 {
  height: 90px;
}

.fixed-spacer-2 {
  height: 140px;
}

.fixed-spacer-3 {
  height: 220px;
}

.fixed-spacer-4 {
  height: 300px;
}

.hover-white-bg:hover,
.custom-panel tbody:hover {
  /* background-color: #fff; */
}

.hover-white-bg,
.custom-panel tbody:hover {
  transition: background-color 0.5s ease-in-out;
  -moz-transition: background-color 0.5s ease-in-out;
  -webkit-transition: background-color 0.5s ease-in-out;
}

.loading {
  padding: 50px 0 100px 0;
}

.text-sm {
  font-size: 80%;
}

.hover-subtotals,
.hover-parents,
.hover-totals {
  transition: box-shadow 1s ease-in-out;
  -moz-transition: box-shadow 1s ease-in-out;
  -webkit-transition: box-shadow 1s ease-in-out;
  transition: background-color 0.5s ease-in-out;
  -moz-transition: background-color 0.5s ease-in-out;
  -webkit-transition: background-color 0.5s ease-in-out;
}

.hover-subtotals:hover {
  box-shadow: 0 0 0px #bbb;
  margin-right: -4px;
  border-right: 0px solid #000;
  margin-left: -4px;
  border-left: 0px solid #000;
  background-color: #fff;
}

.hover-parents:hover {
  box-shadow: 0 0 0px #bbb;
  background-color: #fff;
  margin-right: 0px;
  border-right: 0px solid #bbb;
  margin-left: 0px;
  border-left: 0px solid #bbb;
}

.hover-totals:hover {
  /* box-shadow: 0 0 0px #bbb; */
  /* background-color: #fff; */
}

.link:hover {
  text-decoration: underline;
}

.nowrap {
  white-space: nowrap !important;
}

.overflow-hidden {
  overflow: hidden;
}

a:hover,
.close-icon:hover,
.pointer:hover,
.dropdown-toggle:hover {
  cursor: pointer;
}

.hover {
  cursor: pointer;
}

.block {
  display: block;
}

thead {
  display: table-header-group;
}

h1,
h2,
h3,
h4 {
  margin-top: 0;
}

label {
  font-weight: normal;
  color: #444;
}

label.required {
  font-weight: bold;
  color: #444;
}

.close-icon {
  float: right;
}

.table-striped {
  background-color: #fcfcfc;
  border: 0px solid #eee;
}

.text-bold {
  font-weight: bold;
}

.glyphicon {
  min-width: 20px;
}

.navbar {
  box-shadow: 0 1px 2px #ccc;
}

.tr-hover td:hover {
  /* text-decoration: underline;*/
}

.tr-hover th:hover {
  background-color: #f5f5f5;
}

.select-options-wrapper {
  position: relative;
}

.select-options {
  position: absolute;
  background-color: #fff;
  z-index: 100;
  width: 100%;
  max-height: 200px;
  overflow: auto;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px #ccc;
  margin-top: -2px;
}

.select-options div {
  display: block;
  padding: 6px;
  border-bottom: 1px solid #ccc;
}

.select-options div:hover {
  cursor: pointer;
  background-color: #eee;
}

.login-wrapper {
  margin: 5vh auto 0 auto;
  max-width: 450px;
}

[hidden] {
  display: none !important;
}

.password-hidden,
.input-hidden {
  height: 0;
  width: 0;
  border: 1px solid #fefefe;
}

.input-hidden {
  /*	margin:-30px auto 0 50px;	*/
}

.list-table {
  max-height: 76vh;
  overflow: auto;
}

.audit-table {
  max-height: 40vh;
  overflow: auto;
}

.table {
  margin-bottom: 0;
}

select:hover {
  cursor: pointer;
}

.print-export {
  padding-top: 12px;
}

.w1 {
  width: 1px;
}

.w5 {
  width: 5%;
}

.w8 {
  width: 8%;
}

.w10 {
  width: 10%;
}

.w15 {
  width: 15%;
}

.w20 {
  width: 20%;
}

.w25 {
  width: 25%;
}

.w30 {
  width: 30%;
}

.w35 {
  width: 35%;
}

.w40 {
  width: 40%;
}

.w45 {
  width: 45%;
}

.w50 {
  width: 50%;
}

.input-table tbody td {
  border: none !important;
}

table input.form-control,
table select.form-control {
  border-radius: 0px;
  padding: 0px 3px !important;
  height: 36px;
  /* background-color: #fff; */
  margin: 0 !important;
}

.input-td .input-sm {
  height: 18px !important;
  padding: 0px 2px !important;
  margin: 0 !important;
}

table textarea.form-control {
  border-radius: 0px;
  padding: 3px !important;
  line-height: 28px !important;
  /* background-color: #fff; */
  margin: 0 !important;
}

table .input-group-btn .btn {
  border-color: #eee !important;
  border-radius: 0px;
  padding: 3px !important;
  line-height: 24px !important;
  height: 30px;
}

.btn-search {
  position: absolute;
  top: 2px;
  right: 2px;
  padding: 6px 3px;
}

.btn-search .glyphicon {
  background-color: #fff;
}

.btn-search:hover {
  cursor: pointer;
  color: #000 !important;
}

.input-td {
  padding: 4px !important;
  position: relative;
}
/* to go back to redish #b35900
#payables a, .payables, .payables .panel-heading, .payables label, .payables .list-group-item {
  color: #b35900;*/

/* #payables a,
.payables,
.payables .panel-heading,
.payables label,
.payables .list-group-item {
  color: #333333;
} */
/* to go back to green #008000 */
/* #receivables a,
.receivables,
.receivables .panel-heading,
.receivables label,
.receivables .list-group-item {
  color: #333333;
} */

.dropdown-menu .active a {
  color: #ffffff !important;
}

.panel-title {
  font-size: 140%;
}

.check-label {
  padding: 5px 10px;
  margin: -5px -10px;
  cursor: pointer;
}

.visible-hover .make-visible {
  visibility: hidden;
}

.visible-hover:hover .make-visible {
  visibility: visible;
}

.well-sm {
  padding-top: 6px;
  padding-bottom: 6px;
  margin-bottom: 2px;
}

.custom-panel {
  /* background-color: #fff; */
  box-shadow: 0 0 0;
  border-radius: 0px;
  margin-bottom: 10px;
}

.custom-panel .panel-heading {
  padding: 8px;
}

.modal-body .list-group {
  max-height: 60vh;
  overflow-y: auto;
}

.code {
  /*font-family: consolas;*/
}

.pagination a {
  min-width: 90px;
  text-align: center;
}

.print-invoice {
  margin-top: 20px;
  border: 1px solid gray;
  width: 100%;
  height: 95vh;
  /*padding: 5px;*/
  border-radius: 5px;
  margin-bottom: 20px;
}

.print-invoice td {
  vertical-align: top;
}

.ui-notification {
  margin-top: 60px;
}

.table-checkbox {
  width: 100%;
  margin-bottom: 0px;
  border-radius: 5px;
  padding-left: 5px;
}

.table-checkbox:hover {
  background-color: #fff;
}

.spacer {
  height: 8px;
}

.signature-pad {
  border: 1px solid #bbb;
}

/* Modify brand and text color */
.navbar-custom .navbar-text {
  color: black !important;
}
/* Modify brand and text color */
/*.navbar-custom .navbar-brand {
  color: green;
}*/

/* toast colors */


.toast-error {
  background-color: rgba(250,92,124,1) !important;
  width: 300px !important;
  min-height: 80px !important;
  border-radius: 4px !important;
  box-shadow: 0 3px 5px rgba(100, 100, 100, 0.5) !important;
  margin: 5px 30px 5px 0 !important;
}

.toast-warning {
  background-color: rgba(255,188,3,.77) !important;
  width: 300px !important;
  min-height: 80px !important;
  border-radius: 4px !important;
  box-shadow: 0 3px 5px rgba(100, 100, 100, 0.5) !important;
  margin: 5px 30px 5px 0 !important;
}

.toast-success {
  background-color: rgba(4,207,152,1) !important;
  width: 300px !important;
  min-height: 80px !important;
  border-radius: 4px !important;
  box-shadow: 0 3px 5px rgba(100, 100, 100, 0.5) !important;
  margin: 5px 30px 5px 0 !important;
}

.toast-info {
  background-color: dodgerblue !important;
  width: 300px !important;
  min-height: 80px !important;
  border-radius: 4px !important;
  box-shadow: 0 3px 5px rgba(100, 100, 100, 0.5) !important;
  margin: 5px 30px 5px 0 !important;
}
/*
.toast-container > div {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
no shadow */



#fromDatePicker {
  height: 30px;
  cursor: pointer !important;
}

#toDatePicker {
  height: 30px;
  cursor: pointer !important;
}

.success {
  background-color: #dff0d8;
}
.danger {
  background-color: #f2dede;
}

@media (max-width: 1190px) {
  .visible-hover .make-visible {
    visibility: visible;
  }

  td,
  th {
    font-size: 90% !important;
  }
}

@media (min-width: 1190px) {
  .table-responsive {
    overflow-x: visible;
  }
}

@media (max-width: 769px) {
  label {
    margin: 5px 0 0 0;
  }
}

@media print {
  body {
    padding-bottom: 0px;
  }

  /*a[ui-sref]:after {*/
  /*  content: none !important;*/
  /*}*/

  .fixed-spacer-1,
  .fixed-spacer-2,
  .fixed-spacer-3,
  .fixed-spacer-4 {
    display: none;
  }

  .table-responsive {
    overflow-x: visible !important;
  }

  .ui-notification {
    display: none;
  }

  .btn,
  .pagination {
    display: none !important;
  }

  .table-responsive,
  .list-table {
    max-height: none;
    overflow: visible;
  }

  .table td {
    padding: 4px !important;
    border-color: #555;
    color: #000;
  }

  .table-print {
    width: 750px !important;
  }

  .table-print th {
    border-bottom: 0px solid #555;
  }

  .table-print td {
    padding: 5px 5px 5px 0;
    border-bottom: 0px solid #555;
    vertical-align: top;
  }
  #toast-container {
    visibility: hidden !important;
  }
}
