//
// Datepicker
//

ngb-datepicker {
  border-color: #{map-get($grays, "300")};
  .ngb-dp-header {
    background-color: transparent;
    padding: 0.25rem 0;
    .ngb-dp-navigation-select {
      & > .form-select {
        margin: 0 3px;
      }
    }
    .ngb-dp-arrow-btn {
      color: #{map-get($grays, "600")};
      &:focus {
        box-shadow: none;
      }
      .ngb-dp-navigation-chevron {
        width: 0.45rem;
        height: 0.45rem;
      }
    }
  }
  .ngb-dp-week.ngb-dp-weekdays {
    background-color: transparent;
    border-bottom: none;
    .ngb-dp-weekday {
      color: #{map-get($grays, "600")};
      font-weight: $font-weight-bold;
    }
  }

  [ngbDatepickerDayView] {
    line-height: 27px;
    background-color: transparent !important;
  }

  .ngb-dp-week-number,
  .ngb-dp-weekday,
  .ngb-dp-day {
    font-style: inherit;
    color: #{map-get($grays, "600")};
    width: 32px;
    height: 27px;
    line-height: 27px;
  }

  .ngb-dp-day {
    &:focus {
      outline: none !important;
    }
  }

  .ngb-dp-day,
  .ngb-dp-today {
    .btn-light {
      width: 32px;
      height: 24px;
      border-radius: 5px;
      font-size: 12px;
      color: #{map-get($grays, "700")};
      box-shadow: none;
      &.active {
        border: none;
      }
      &.bg-primary {
        font-weight: bold;
        background-color: $primary !important;
      }
    }
  }

  .ngb-footer-button {
    padding: 8px;
    text-align: right;

    .btn-sm {
      font-size: 0.75rem;
    }

    .btn-default {
      margin-left: 8px;
      font-size: 12px;
      font-weight: bold;
      padding: 4px 8px;
    }
  }
}

.calendar-widget {
  padding: 10px;
  ngb-datepicker {
    width: 100%;
    height: 100%;
    // padding: 0 20px;
    // padding-bottom: 0px;
    .ngb-dp-day {
      height: 43px;
      line-height: 43px;
    }
    [ngbDatepickerDayView] {
      line-height: 43px;
      background-color: transparent !important;
    }
    .ngb-dp-content {
      .ngb-dp-month {
        .ngb-dp-week {
          .ngb-dp-day {
            .btn-light {
              width: 100% !important;
              height: 100% !important;
            }
            &.ngb-dp-today {
              .btn-light {
                color: $danger;
                font-weight: $font-weight-bold;
              }
            }
          }
        }
      }
    }

    border: none;
    .ngb-dp-header {
      background-color: #{map-get($grays, "200")} !important;
      margin-bottom: 5px;
      // padding: 5px auto;
      .ngb-dp-arrow {
        font-size: 1.1rem;
        border-radius: 0;
        .btn {
          color: $primary;
        }
      }
    }

    .ngb-dp-content {
      margin-top: 5px;
      .ngb-dp-month {
        width: 100%;
        .ngb-dp-week {
          width: 100%;
          justify-content: space-between;
          background-color: transparent !important;
          height: 43px;
          .ngb-dp-weekday.small {
            font-size: 13px;
            font-style: normal;
            color: #{map-get($grays, "700")};
            font-weight: bold;
            height: 43px;
            line-height: 43px;
          }

          .ngb-dp-day {
            .btn-light {
              width: 30px;
              height: 30px;
              border-radius: 50%;
              font-size: 15px;
              background-color: transparent !important;
              &.bg-primary {
                color: $danger !important;
                text-shadow: none;
                font-weight: $font-weight-bold;
              }
            }
          }
        }
      }
    }
  }
}

body[data-layout-color="dark"] {
  ngb-datepicker {
    border-color: #{map-get($dark-grays, "300")};
    .ngb-dp-header {
      .ngb-dp-arrow-btn {
        color: #{map-get($dark-grays, "600")};
      }
    }
    .ngb-dp-week.ngb-dp-weekdays {
      .ngb-dp-weekday {
        color: #{map-get($dark-grays, "600")};
      }
    }

    .ngb-dp-week-number,
    .ngb-dp-weekday,
    .ngb-dp-day {
      color: #{map-get($dark-grays, "600")};
    }

    .ngb-dp-day,
    .ngb-dp-today {
      .btn-light {
        color: #{map-get($dark-grays, "600")};
      }
    }
  }

  .calendar-widget {
    ngb-datepicker {
      border: none;
      .ngb-dp-header {
        background-color: #{map-get($dark-grays, "200")} !important;
        margin-bottom: 5px;
      }

      .ngb-dp-content {
        .ngb-dp-month {
          .ngb-dp-week {
            .ngb-dp-weekday.small {
              color: #{map-get($dark-grays, "700")};
            }
          }
        }
      }
    }
  }
}
