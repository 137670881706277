// Utilities

@import "utilities/background";
@import "utilities/text";


// border style
.border-dashed {
    border-style: dashed !important;
}

// Dark mode
body[data-layout-color="dark"] {
    @each $color, $value in $dark-theme-colors {
        .border-#{$color} {
            border-color: $value !important;
        }
    }
}

/* warn badge for no records found */
.warning-no-data {
  cursor: default;
  margin: 10px 0;
  padding: 8px 10px;
  color: $white;
  border-radius: 4px;
}

/*  end  */
