//
// pagination.scss
//

// Pagination rounded (Custom)
.pagination-rounded {
  .page-link {
    border-radius: 30px !important;
    margin: 0 3px !important;
    border: none;
  }
}

// Pagination acctx (Custom)
.pagination-acctx {
  .page-link {
    width: 30px !important;
    height: 30px !important;
    margin: 0 3px !important;
    border-radius: 40% !important;

  }
}
